<template>
  <v-container fluid class="ma-0 pa-0 white">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.lgAndUp" :pa-3="$vuetify.breakpoint.mdAndDown">
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-layout row :wrap="$vuetify.breakpoint.smAndDown" py-5 fill-height>
          <v-flex xs12 offset-md1 md2>
            <p class="graphik-light font-25 dark-grey--text">{{ $ml.get('quote_results_quoting') }}:</p>
          </v-flex>

          <v-flex xs12 md6 :px-3="$vuetify.breakpoint.mdAndUp">
            <p class="graphik-medium normalcase font-25 primary--text" v-if="$route.params.type === 'auto'">{{ quote.car_version }}</p>
            <p class="graphik-medium normalcase font-25 primary--text" v-if="$route.params.type === 'hogar'">{{ quote.street }} {{ quote.street_number }} {{ quote.street }} {{ quote.zip_code }}</p>

            <v-layout row wrap>
              <v-flex xs4>
                <p class="graphik-medium normalcase font-15 mb-4 primary--text">
                  {{ $ml.get('fields_coverage') }}:

                  <span class="graphik-light mr-3" v-if="$route.params.type === 'auto'">
                    {{ options.policy_types_car.find(type => type.value === selection.policy_type).text }}
                  </span>

                  <span class="graphik-light mr-3" v-if="$route.params.type === 'hogar'">
                    {{ options.policy_types_house.find(type => type.value === selection.policy_type).text }}
                  </span>
                </p>
              </v-flex>

              <v-flex xs8>
                <p class="graphik-medium normalcase font-15 mb-4 primary--text">          
                  {{ $ml.get('fields_validity') }}:
                  <span class="graphik-light" v-if="quote.start_date">{{ quote.start_date | moment('LL') }} {{ $ml.get('general_to') }} {{ quote.end_date | moment('LL') }}</span>
                </p>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 md2 align-self-center text-xs-right>
            <v-btn 
              color="primary"
              dark depressed
              :loading="g_isLoading"
              class="lowercase graphik-bold-italic mx-0 mb-3 border-radius-5"
              :disabled="g_isLoading"
              @click="$goTo('/' + $route.params.type + query, 'goto_back_' + $route.params.type)"
            >{{ $ml.get('general_edit_information') }}<v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
          </v-flex>
        </v-layout>

        <v-layout row wrap v-if="loading_quotes_result && $vuetify.breakpoint.smAndDown">
          <v-flex xs12>
            <v-card flat tile class="gradient">
              <v-img
                width="80px"
                class="absolute"
                :src="images.cuco_hand"
                :lazy-src="images.cuco_hand"
              />

              <v-card-text :class="{'pa-5': $vuetify.breakpoint.smOnly}">
                <v-layout align-center>
                  <v-progress-circular indeterminate color="secondary" :size="50" class="mr-3"></v-progress-circular>
                  
                  <div>
                    <p
                      class="graphik-bold px-1 secondary--text"
                      :class="{'font-17': $vuetify.breakpoint.smOnly, 'font-11 max-width-200': $vuetify.breakpoint.xsOnly}"
                    >{{ $ml.get('hint_loading') }}</p>

                    <v-img
                      max-width="250px"
                      contain
                      position="center right"
                      :src="images.logo"
                      :lazy-src="images.logo"
                    />
                  </div>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 md6 py-3 :px-4="$vuetify.breakpoint.mdAndUp">
            <quote-result
              :quote="cheapest_quote"
              :payment_type="selection.payment_type"
              :policy_type="selection.policy_type"
              :title="$ml.get('quote_results_cheapest')"
            ></quote-result>
          </v-flex>

          <v-flex xs12 md6 py-3 :px-4="$vuetify.breakpoint.mdAndUp">
            <quote-result
              :quote="most_bought_quote"
              :quote_data="quote"
              :payment_type="selection.payment_type"
              :policy_type="selection.policy_type"
              :title="$ml.get('quote_results_most_bought')"
            ></quote-result>
          </v-flex>
        </v-layout>

        <v-layout row wrap py-5 v-if="loading_quotes_result && $vuetify.breakpoint.mdAndUp">
          <v-flex xs12>
            <v-card flat tile class="gradient">
              <v-img
                width="150px"
                class="absolute"
                :src="images.cuco_hand"
                :lazy-src="images.cuco_hand"
              />

              <v-card-text class="pa-5">
                <v-layout align-center>
                  <v-progress-circular indeterminate color="secondary" :size="50" class="mr-3"></v-progress-circular>
                  
                  <div>
                    <p class="graphik-bold font-20 px-1 secondary--text">{{ $ml.get('hint_loading') }}</p>

                    <v-img
                      max-width="250px"
                      contain
                      position="center right"
                      :src="images.logo"
                      :lazy-src="images.logo"
                    />
                  </div>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap :py-5="$vuetify.breakpoint.mdAndUp">
          <v-flex xs12 sm8 md9 :pl-3="$vuetify.breakpoint.smAndUp" v-if="$vuetify.breakpoint.xsOnly">
            <v-layout row wrap :pb-5="$vuetify.breakpoint.smAndUp">
              <template v-for="(quote_result, index) in ordered_quotes">
                <v-flex
                  xs12
                  :key="index"
                >
                  <quote-result
                    :quote="quote_result"
                    :quote_data="quote"
                    :payment_type="selection.payment_type"
                    :policy_type="selection.policy_type"
                  ></quote-result>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm4 md3 :pr-3="$vuetify.breakpoint.smAndUp">
            <v-card flat tile class="light-background my-3">
              <v-card-text>
                <p class="graphik-medium font-25 dark-grey--text">{{ $ml.get('fields_coupon') }}</p>

                <v-text-field
                  v-model="selection.coupon"
                  solo
                  clearable
                  :disabled="g_isLoading"
                  class="hidden-shadow small-input"
                  append-outer-icon="send"
                  @click:append-outer="applyCode"
                ></v-text-field>

                <p class="graphik-medium font-25 dark-grey--text" :class="{'mt-5': $vuetify.breakpoint.smAndUp}">{{ $ml.get('fields_coverage') }}</p>

                <v-radio-group v-model="selection.policy_type" column @change="getQuotes()" v-if="$route.params.type === 'auto'">
                  <v-radio
                    v-for="(policy_type, index) in options.policy_types_car"
                    :key="index"
                    :label="policy_type.text"
                    color="primary"
                    :value="policy_type.value"
                  ></v-radio>
                </v-radio-group>

                <v-radio-group v-model="selection.policy_type" column @change="getQuotes()" v-if="$route.params.type === 'hogar'">
                  <v-radio
                    v-for="(policy_type, index) in options.policy_types_house"
                    :key="index"
                    :label="policy_type.text"
                    color="primary"
                    :value="policy_type.value"
                  ></v-radio>
                </v-radio-group>

                <p class="graphik-medium font-25 dark-grey--text" :class="{'mt-5': $vuetify.breakpoint.smAndUp}">{{ $ml.get('fields_payment_method') }}</p>

                <v-radio-group v-model="selection.payment_type" column @change="getQuotes()">
                  <v-radio
                    v-for="(payment_type, index) in options.payment_types"
                    :key="index"
                    :label="payment_type.text"
                    color="primary"
                    :value="payment_type.value"
                  ></v-radio>
                </v-radio-group>

                <p class="graphik-medium font-25 dark-grey--text" :class="{'mt-5': $vuetify.breakpoint.smAndUp}">{{ $ml.get('fields_validity') }}</p>

                <v-menu
                  :close-on-content-click="false"
                  v-model="validity_menu"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    solo
                    slot="activator"
                    v-model="validity_formatted"
                    persistent-hint
                    readonly
                    append-icon="event"
                    required
                    :disabled="g_isLoading"
                    :rules="g_required_rules"
                    class="hidden-shadow small-input"
                  ></v-text-field>

                  <v-date-picker
                    ref="validity_picker"
                    v-model="selection.validity"
                    @change="updateCarQuote"
                    no-title
                    locale="es-ES"
                    :min="today"
                    :max="one_month"
                    @input="validity_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
            </v-card>

            <quoter-faq />
          </v-flex>

          <v-flex xs12 sm8 md9 :pl-3="$vuetify.breakpoint.smAndUp" v-if="$vuetify.breakpoint.smAndUp">
            <v-layout row wrap :pb-5="$vuetify.breakpoint.smAndUp">
              <template v-for="(quote_result, index) in ordered_quotes">
                <v-flex
                  xs12
                  :key="index"
                >
                  <quote-result
                    :quote="quote_result"
                    :quote_data="quote"
                    :payment_type="selection.payment_type"
                    :policy_type="selection.policy_type"
                  ></quote-result>
                </v-flex>
              </template>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'QuoteResults',
  components: {
    'quoter-faq': () => import(/* webpackPrefetch: true */ '../components/QuoterFAQ'),
    'quote-result': () => import(/* webpackPrefetch: true */ '../components/QuoteResult')
  },
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_QUOTE_RESULTS,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_QUOTE_RESULTS
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_QUOTE_RESULTS
      }
    ]
  },
  data () {
    return {
      images: {
        cuco_hand: require('@/assets/img/images/cuco_hand.png'),
        logo: require('@/assets/img/brand/logo-purple.png')
      },
      quote: {},
      quotes: [],
      is_loading_quotes: {},
      loading_quotes_result: false,
      validity_menu: false,
      selection: {
        payment_type: '1',
        policy_type: 'ALTA',
        coupon: '',
        validity: null
      },
      config: {
        ALTA: {
          '1': false,
          '2': false,
          '4': false,
          '12': false
        },
        MEDIA: {
          '1': false,
          '2': false,
          '4': false,
          '12': false
        },
        BAJA: {
          '1': false,
          '2': false,
          '4': false,
          '12': false
        }
      },
      options: {
        companies: ['ana', 'axa', 'chubb', 'gnp', 'primeroSeguros', 'qualitas', 'afirme', 'hdi', 'mapfre'],
        uber_companies: ['axa', 'gnp', 'primeroSeguros', 'qualitas'],
        house_plans: ['1', '2', '3', '4'],
        payment_types: [
          {
            value: '1',
            text: this.$ml.get('fields_annual')
          },
          {
            value: '2',
            text: this.$ml.get('fields_biannual')
          },
          {
            value: '4',
            text: this.$ml.get('fields_quaterly')
          },
          {
            value: '12',
            text: this.$ml.get('fields_monthly')
          }
        ],
        policy_types_house: [
          {
            value: 'ALTA',
            text: this.$ml.get('fields_premium')
          },
          {
            value: 'MEDIA',
            text: this.$ml.get('fields_optimo')
          },
          {
            value: 'BAJA',
            text: this.$ml.get('fields_basico')
          }
        ],
        policy_types_car: [
          {
            value: 'ALTA',
            text: this.$ml.get('fields_amplia')
          },
          {
            value: 'MEDIA',
            text: this.$ml.get('fields_limitada')
          },
          {
            value: 'BAJA',
            text: this.$ml.get('fields_rc')
          }
        ]
      }
    }
  },
  computed: {
    query () {
      let query = '?'

      if (this.$route.params.type === 'auto') {
        query += 'car_model=' + this.quote.car_model +
        '&car_brand=' + this.quote.car_brand +
        '&car_version_uuid=' + this.quote.car_version_uuid +
        '&birthday=' + this.quote.birthday +
        '&zip_code=' + this.quote.zip_code +
        '&gender=' + this.quote.gender +
        '&name=' + this.quote.name +
        '&email=' + this.quote.email +
        '&cellphone=' + this.quote.cellphone
      }

      if (this.$route.params.type === 'hogar') {
        query += 'street=' + this.quote.street +
        '&street_number=' + this.quote.street_number +
        '&neighborhood_uuid=' + this.quote.neighborhood_uuid +
        '&home_type_uuid=' + this.quote.home_type_uuid +
        '&birthday=' + this.quote.birthday +
        '&zip_code=' + this.quote.zip_code +
        '&gender=' + this.quote.gender +
        '&name=' + this.quote.name +
        '&email=' + this.quote.email +
        '&cellphone=' + this.quote.cellphone
      }

      return query
    },
    cheapest_quote () {
      if (!this.ordered_quotes[0]) {
        return {}
      }

      return this.ordered_quotes[0]
    },
    most_bought_quote () {
      if (!this.quote.insurance_lock) {
        if (!this.ordered_quotes[0]) {
          return {}
        }

        return this.ordered_quotes[0]
      }

      return this.ordered_quotes.find(quote => quote.company === this.quote.insurance_lock.name)
    },
    ordered_quotes () {
      let ordered_quotes = this.quotes
      ordered_quotes.sort((a, b) => {
        if (!a[this.selection.policy_type][this.selection.payment_type].prices || typeof a[this.selection.policy_type][this.selection.payment_type].prices.total === 'string') return 1
        if (!b[this.selection.policy_type][this.selection.payment_type].prices || typeof b[this.selection.policy_type][this.selection.payment_type].prices.total === 'string') return -1
        return a[this.selection.policy_type][this.selection.payment_type].prices.total - b[this.selection.policy_type][this.selection.payment_type].prices.total
      })
      return ordered_quotes
    },
    validity_formatted: {
      get () {
        if (!this.selection.validity) return null

        const [year, month, day] = this.selection.validity.split('-')
        return `${day}/${month}/${year}`
      },
      set () {
        this.selection.validity = this.selection.validity
      }
    },
    today () {
      return this.$moment().format('YYYY-MM-DD')
    },
    one_month () {
      return this.$moment().add(28, 'days').format('YYYY-MM-DD')
    }
  },
  methods: {
    getCarQuoteDetails () {
      this.g_isLoading = true
      this.$api.getCarQuote(
        this.$route.params.id,
        response => {
          this.quote = response.body.data
          this.selection.coupon = response.body.data.coupon.coupon_code
          this.selection.validity = this.quote.start_date
          this.g_isLoading = false
          this.getCarQuotes()
        },
        response => this.$errorHandling(response).then(() => {
          this.getCarQuoteDetails()
        }, () => {})
      )
    },
    getHouseQuoteDetails () {
      this.g_isLoading = true
      this.$api.getHouseQuote(
        this.$route.params.id,
        response => {
          this.quote = response.body.data
          this.selection.coupon = response.body.data.coupon.coupon_code
          this.selection.validity = this.quote.start_date
          this.g_isLoading = false
          this.getHouseQuotes()
        },
        response => this.$errorHandling(response).then(() => {
          this.getHouseQuoteDetails()
        }, () => {})
      )
    },
    updateCarQuote () {
      this.g_isLoading = true
      this.$api.updateCarQuote(
        this.$route.params.id,
        { coupon_code: this.selection.coupon, start_date: this.selection.validity },
        () => {
          this.quotes = []
          this.config = {
            ALTA: {
              '1': false,
              '2': false,
              '4': false,
              '12': false
            },
            MEDIA: {
              '1': false,
              '2': false,
              '4': false,
              '12': false
            },
            BAJA: {
              '1': false,
              '2': false,
              '4': false,
              '12': false
            }
          }

          this.getCarQuotes()
        },
        response => this.$errorHandling(response).then(() => {
          this.updateCarQuote()
        }, () => {})
      )
    },
    updateHouseQuote () {
      this.g_isLoading = true
      this.$api.updateHouseQuote(
        this.$route.params.id,
        { coupon_code: this.selection.coupon, start_date: this.selection.validity },
        () => {
          this.quotes = []
          this.config = {
            ALTA: {
              '1': false,
              '2': false,
              '4': false,
              '12': false
            },
            MEDIA: {
              '1': false,
              '2': false,
              '4': false,
              '12': false
            },
            BAJA: {
              '1': false,
              '2': false,
              '4': false,
              '12': false
            }
          }

          this.getHouseQuotes()
        },
        response => this.$errorHandling(response).then(() => {
          this.updateHouseQuote()
        }, () => {})
      )
    },
    getCarQuotePdf () {
      this.$api.getCarQuotePDF(
        this.$route.params.id,
        () => {},
        response => this.$errorHandling(response).then(() => {
          this.getCarQuotePdf()
        }, () => {})
      )
    },
    getCarQuotes () {
      if (this.config[this.selection.policy_type][this.selection.payment_type]) {
        return
      }

      this.config[this.selection.policy_type][this.selection.payment_type] = true
      if (this.quote.driver_type === 'UBER') {
        this.options.uber_companies.map(company => {
          this.getCarQuote(company, this.$route.params.id, this.selection.payment_type, this.selection.policy_type)
        })
      } else {
        this.options.companies.map(company => {
          this.getCarQuote(company, this.$route.params.id, this.selection.payment_type, this.selection.policy_type)
        })
      }
    },
    getHouseQuotes () {
      if (this.config[this.selection.policy_type][this.selection.payment_type]) {
        return
      }

      this.config[this.selection.policy_type][this.selection.payment_type] = true
      this.options.house_plans.map(plan => {
        this.getHouseQuote('chubb', this.$route.params.id, this.selection.payment_type, this.selection.policy_type, plan)
      })
    },
    getCarQuote (company, quote, payment, level) {
      this.is_loading_quotes[company + payment + level] = true
      this.loading_quotes_result = true

      let quote_result = this.quotes.find(q => q.company === company)

      if (!this.quotes.find(q => q.company === company)) {
        this.createCarQuoteResult(company)
        quote_result = this.quotes.find(q => q.company === company)
      }

      this.$api.getCarQuoteByCompany(company, quote, payment, level, '0',
        response => {
          if (response.body.data) {
            quote_result[level][payment] = response.body.data
          }

          this.is_loading_quotes[company + payment + level] = false
          this.checkLoading()
        },
        response => this.$errorHandling(response).then(() => {
          this.getCarQuote(company, quote, payment, level)
        }, () => {
          if (response.body.error === 56) {
            quote_result[level][payment] = {
              error: true
            }
          }
          this.is_loading_quotes[company + payment + level] = false
          this.checkLoading()
        })
      )
    },
    getHouseQuote (company, quote, payment, level, plan) {
      this.is_loading_quotes[company + payment + level + plan] = true
      this.loading_quotes_result = true

      let quote_result = this.quotes.find(q => q.plan === plan)

      if (!this.quotes.find(q => q.plan === plan)) {
        this.createHouseQuoteResult(plan)
        quote_result = this.quotes.find(q => q.plan === plan)
      }

      this.$api.getHouseQuoteByCompany(company, quote, payment, level, plan,
        response => {
          if (response.body.data) {
            quote_result[level][payment] = response.body.data
          }

          this.is_loading_quotes[company + payment + level + plan] = false
          this.checkLoading()
        },
        response => this.$errorHandling(response).then(() => {
          this.getHouseQuote(company, quote, payment, level, plan)
        }, () => {
          if (response.body.error === 56) {
            quote_result[level][payment] = {
              error: true
            }
          }
          this.is_loading_quotes[company + payment + level + plan] = false
          this.checkLoading()
        })
      )
    },
    // Helping functions
    getQuotes () {
      if (this.$route.params.type === 'auto') {
        this.getCarQuotes()
      } else if (this.$route.params.type === 'hogar') {
        this.getHouseQuotes()
      }
    },
    checkQuote () {
      if (this.$route.params.type === 'auto') {
        this.getCarQuoteDetails()
      } else if (this.$route.params.type === 'hogar') {
        this.getHouseQuoteDetails()
      }
    },
    applyCode () {
      this.$analytics('quoter_apply_code_' + this.selection.code)

      if (this.$route.params.type === 'auto') {
        this.updateCarQuote()
      } else if (this.$route.params.type === 'hogar') {
        this.updateHouseQuote()
      }
    },
    createCarQuoteResult (company) {
      let quote = {
        company: company
      }
      this.options.policy_types_car.map(policy => {
        quote[policy.value] = {}
        this.options.payment_types.map(payment => {
          quote[policy.value][payment.value] = {}
        })
      })
      this.quotes.push(quote)
    },
    createHouseQuoteResult (plan) {
      let quote = {
        company: 'chubbhogar-' + plan,
        plan: plan
      }
      this.options.policy_types_car.map(policy => {
        quote[policy.value] = {}
        this.options.payment_types.map(payment => {
          quote[policy.value][payment.value] = {}
        })
      })
      this.quotes.push(quote)
    },
    checkLoading () {
      let result = false
      Object.keys(this.is_loading_quotes).map(key => {
        if (this.is_loading_quotes[key]) {
          result = true
        }
      })
      this.loading_quotes_result = result

      if (!result && this.$route.params.type === 'auto') {
        this.getCarQuotePdf()
      }
    }
  },
  mounted () {
    if (this.$route.params.type === 'auto') {
      this.options.uber_companies.map(company => {
        this.createCarQuoteResult(company)
      })
    }

    if (this.$route.params.type === 'hogar') {
      this.options.house_plans.map(plan => {
        this.createHouseQuoteResult(plan)
      })
    }
    
    this.checkQuote()
  }
}
</script>

<style scoped>
  .gradient {
    background-image: linear-gradient(to right, #8efac5, #05eebd) !important;
  }

  .absolute {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
  }

</style>
